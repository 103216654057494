'use strict';

module.exports = function (m, tipos, tipoInicial, element, isAdmin) {
  var tipo = {};
  tipo.id = m.prop(tipoInicial ? tipoInicial : tipos[0].id);

  function convertToTitleCase(value) {
    return value.replace('_', ' ').replace(/\w\S*/g,
      function (text) {
        return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
      }
    );
  }

  function convertToSelect2Data(originalArray) {
    var newArray = [];

    for (var i = 0; i < originalArray.length; i++) {
      newArray.push({
        id: originalArray[i].id,
        text: convertToTitleCase(originalArray[i].nome)
      });
    }

    return newArray;
  }

  function tratarCamposUsuarioApi() {
      $('#permissoes-api').show();
      $('#papel-components').hide();
      $('#label-servidor').hide();
      $('#cpf-components').hide();
  }

  function tratarCamposUsuarioEditor() {
      $('#permissoes-api').hide();
      $('#papel-components').show();
      $('#label-servidor').show();
      $('#cpf-components').show();
  }

  tipo.view = function () {

    if(tipo.id() == '1') {
        tratarCamposUsuarioEditor();
    } else {
        tratarCamposUsuarioApi();
    }

    return m('label', [
        m.component(require('componentes/select2'), {
        bloquear: !isAdmin,
        prop: tipo.id,
        tte: {},
        onchange: function (value) {
            if(value == '1') {
                tratarCamposUsuarioEditor();
                $('#cpf').val('');
            } else {
                tratarCamposUsuarioApi();
                $('#cpf').val('-1');
            }
        },
        data: convertToSelect2Data(tipos)
      }),
        m('input[type=hidden]#tipoId', {
        name: 'tipoId',
        value: tipo.id()
      })
    ]);
  };

  m.mount(document.getElementById(element), {
    view: tipo.view
  });
};
